<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <img-logo />
      <b-col lg="6" class="recover-password">
        <h2 class="recover-password__title">Acompanhe o seu e-mail</h2>
        <p class="recover-password__paragraph">
          Para resetar a sua senha, digite o código que foi enviado para o seu
          e-mail.
        </p>

        <validation-observer ref="simpleRules">
          <b-form>
            <b-row class="recover-password__inpcp-container">
              <b-col sm="3" md="3" lg="3">
                <validation-provider name="Email" rules="required">
                  <b-form-input class="inpcp" type="number" placeholder="9" />
                </validation-provider>
              </b-col>
              <b-col sm="3" md="3" lg="3">
                <validation-provider name="Email" rules="required">
                  <b-form-input class="inpcp" type="number" placeholder="9" />
                </validation-provider>
              </b-col>
              <b-col sm="3" md="3" lg="3">
                <validation-provider name="Email" rules="required">
                  <b-form-input class="inpcp" type="number" placeholder="9" />
                </validation-provider>
              </b-col>
              <b-col sm="3" md="3" lg="3">
                <validation-provider name="Email" rules="required">
                  <b-form-input class="inpcp" type="number" placeholder="9" />
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
          <b-button
            class="recover-password__button"
            type="submit"
            variant="primary"
            block>
            Continuar
          </b-button>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Voltar
          </b-link>
        </p>
      </b-col>

      <background-card />
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
// import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import {
  required,
  email,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import BackgroundCard from './template/BackgroundCard.vue'
import ImgLogo from './template/ImgLogo.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BButton,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BackgroundCard,
    ImgLogo,
  },
  data() {
    return {
      emailAddress: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      required,
      email,
      reset: {
        emailAddress: null,
      },
      imgLogo: require('@/assets/images/logo/logo.png'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Email enviado. Verifique sua caixa de email.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.recover()
        }
      })
    },
    async recover() {
      try {
        const response = await this.$http.post(
          '/api/services/app/Account/SendPasswordResetCode',
          this.reset,
        )
        if (response) {
          this.$router.push('/recuperar-senha-solicitacao')
        }
      } catch (error) {
        this.errors = error.message
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import './sass/_recover-password.scss';

.inpcp {
  border: 0;
  outline: 0;
  border-bottom: 2px solid #cedbe05e;
  font-size: 1.4rem;
  color: #ccc;
  text-align: center;
}
</style>
